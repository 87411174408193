var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.toggle.shouldPay
    ? _c(
        "div",
        { staticClass: "px-3 border-bottom" },
        [
          _c(
            "a-form-model-item",
            {
              attrs: {
                label: _vm.$t("lbl_total_should_pay"),
                prop: "totalPayment",
                "label-col": _vm.labelCol
              }
            },
            [
              _c("span", { staticClass: "mr-2" }, [
                _vm._v(_vm._s(_vm.CURRENCY_CODE.IDR))
              ]),
              _c("span", [
                _vm._v(_vm._s(_vm._f("currency")(_vm.form.totalPayment)))
              ])
            ]
          )
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }