
















import Vue from "vue";
import { mapState } from "vuex";
import { CURRENCY_CODE } from "@enum/global.enum";
import { IImportCostCreate } from "@/models/interface-v2/import-cost.interface";

export default Vue.extend({
  name: "ImportCostTotalShouldPay",
  data() {
    return {
      CURRENCY_CODE,
      labelCol: { span: 5 },
    };
  },
  computed: {
    ...mapState({
      form: (st: any) => st.importCostStore.form as IImportCostCreate,
      toggle: (st: any) => st.importCostStore.toggleSection as boolean,
    })
  },
});

